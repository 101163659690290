import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24')
];

export const server_loads = [0,2,6];

export const dictionary = {
		"/": [~7],
		"/admin/auswertung/einzelauswertung": [~8,[2],[3]],
		"/admin/auswertung/einzelauswertung/[id]": [~9,[2],[3]],
		"/admin/auswertung/gesamtauswertung": [~10,[2],[3]],
		"/admin/auswertung/gesamtauswertung/[id]": [~11,[2],[3]],
		"/admin/einstellungen": [~12,[2],[3]],
		"/admin/frageboegen": [~13,[2],[3]],
		"/admin/frageboegen/[id]": [~14,[2],[3]],
		"/admin/stammdaten/anforderungsprofile": [~15,[2],[3]],
		"/admin/stammdaten/anforderungsprofile/[id]": [~16,[2],[3]],
		"/admin/stammdaten/bewertungen": [~17,[2],[3]],
		"/admin/stammdaten/bewertungen/[id]": [~18,[2],[3]],
		"/admin/stammdaten/kompetenzen": [~19,[2],[3]],
		"/admin/stammdaten/mitarbeiterInnen": [20,[2],[3]],
		"/admin/stammdaten/mitarbeiterInnen/gespraechsfuehrer/[id]": [~21],
		"/anforderungsprofil/[id]": [~22],
		"/bewertung": [~23,[6]],
		"/bewertung/[id]": [~24,[6]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';