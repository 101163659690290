import { init } from '@jill64/sentry-sveltekit-cloudflare/client'

const onError = init(
  'https://2a82e9166dc34d6937e884171ab629e8@o4507068185903104.ingest.de.sentry.io/4508093418569808',
  {
    sentryOptions: {
      tracesSampleRate: 1.0,

      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.01,

      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,

    }
  }
)

export const handleError = onError((e, sentryEventId) => {
  console.log(e, sentryEventId);
})

